.header {
  height: 80px;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $black-color;
  z-index: 50;
  padding: 0 20px;

  & .logo {
    transition: all .5s;

    &:hover {
      transform: scale(.75);
      cursor: pointer;
    }
  }

  &-desktop {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $md-screen) {
      display: none;
    }

    & .logo {
      margin-right: 20px;
    }
  }

  &-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s;

    @media (min-width: $lg-screen) {
      display: none;
    }
  }

  &-menu {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 2rem 2rem;
    position: fixed;
    top: 80px;
    width: 80%;
    height: calc(100% - 80px);
    overflow-y: auto;
    transition: left .3s;
    left: -100%;
    z-index: 10;

    &.open {
      left: 0;
    }

    @media (min-width: $lg-screen) {
      display: none;
    }

    &-title {
      color: #000;
      font-size: 1.3rem;
      font-weight: 900;
      text-transform: uppercase;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }

    &-item {
      color: #000;
      font-weight: 600;
    }

    &-footer {
      margin-top: 1rem;

      & .title {
        font-weight: 900;
        font-size: 1.3rem;
      }

      & .links {
        display: flex;
        margin-top: .5rem;
        gap: .5rem;

        & a {
          font-size: .75rem;
          font-weight: 600;
          margin-right: .75rem;
        }
      }
    }
  }

  & .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-item {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: background-color .3s;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &:hover {
        background: #fff;
        color: #000;
      }
    }
  }
}