.first-block {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top right, #FF7E00, #F6EC02);

  @media (min-width: $lg-screen) {
    height: calc(100vh - 100px);
    flex-direction: row;
  }
  
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 2.5rem 1.25rem;

    & h2 {
      width: 80%;
      color: $black-color;
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 2.25rem;
      margin-bottom: 1rem;
    }

    & p {
      width: 100%;
      color: $black-color;
      font-weight: 600;
      font-size: 1.1rem;
    }

    @media (min-width: $md-screen) {
      & h2 {
        width: 70%;
        font-size: 4.5rem;
        line-height: 4.5rem;
      }

      & p {
        width: 80%;
        font-size: 1.7rem;
      }
    }

    @media (min-width: $lg-screen) {
      width: 50%;
      padding: 5rem;

      & h2 {
        width: 100%;
        font-size: 1.875rem;
        line-height: 1.875rem;
      }

      & p {
        line-height: 1.2rem;
        width: 100%;
      }
    }

    @media (min-width: $xl-screen) {
      & h2 {
        font-size: 3rem;
        line-height: 3rem;
      }

      & p {
        font-size: 1.2rem;
        line-height: 1.3rem;
      }
    }

    //@media (min-width: $xl2-screen) {
    //  & h2 {
    //    font-size: 8rem;
    //    line-height: 8rem;
    //  }
    //
    //  & p {
    //    width: 75%;
    //    font-size: 2rem;
    //    line-height: 2.2rem;
    //  }
    //}


    &-buttons {
      display: flex;
      width: 100%;
      margin-top: 2.5rem;
      gap: 10px;

      @media (min-width: $lg-screen) {
        margin-top: 1.25rem;
      }

      @media (min-width: $xl-screen) {
        margin-top: 2.5rem;
      }

      @media (min-width: $xl2-screen) {
        margin-top: 5rem;
      }
    }
  }

  &-image {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;

    @media (min-width: $lg-screen) {
      width: 50%;
    }
  }
}