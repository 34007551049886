.advantages {
  display: flex;
  flex-direction: column;

  @media (min-width: $lg-screen) {
    flex-direction: row;
    justify-content: end;
    transform: translateY(-2.5rem);
  }

  &-item {
    width: 100%;
    height: 211px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1.25em;

    @media (min-width: $lg-screen) {
      width: 367px;
    }

    &-index {
      width: 61px;
      height: 61px;
      max-width: 61px;
      max-height: 61px;
      min-width: 61px;
      min-height: 61px;
      border-radius: 50%;
      font-size: 1.25em;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.25em;
    }

    & p {
      font-size: 1.25em;
      font-weight: 700;
    }
  }
}