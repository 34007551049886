.form-price {
  background: #000;
  display: flex;
  flex-direction: column;

  @media (min-width: $lg-screen) {
    margin: 1.25em;
    flex-direction: row;
  }

  &-content {
    width: 100%;
    padding: 2.5em;
    //margin-bottom: 1.25em;

    @media (min-width: $lg-screen) {
      width: 60%;
    }

    & img {
      position: absolute;
      transform: translate(-100px, -300px);
      display: none;

      @media (min-width: $lg-screen) {
        display: block;
      }
    }

    & .title {
      color: $yellow-color;
      font-size: 1.5em;
      font-weight: 900;

      @media (min-width: $lg-screen) {
        font-size: 2em;
      }
    }
  }

  &-form {
    width: 100%;
    padding: 2.5em;

    @media (min-width: $lg-screen) {
      width: 40%;
    }

    &-image {
      background: linear-gradient(359deg, #FFF 0.75%, rgba(255, 255, 255, 0.00) 99.38%);
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        display: block;
        transform: translateY(1.25em);

        @media (min-width: $lg-screen) {
          display: none;
        }
      }
    }
    
    & form {
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 2.5em 1.75em;
      gap: 10px;
      border-radius: 20px;

      & input[type='text'] {
        border: 2px solid #000;
        border-radius: 20px;
        padding: 1em;

        &::placeholder {
          color: #000;
          font-weight: 600;
        }
      }

      & button {
        background: $yellow-color;
        border: 2px;
        border-radius: 20px;
        padding: 1em;
        color: #000;
        font-weight: 600;
        
        &:disabled {
          background: #aaa;
          cursor: no-drop;
        }
      }

      & .allow-policy {
        display: flex;
        align-items: start;

        & label {
          display: block;
        }

        & input[type='checkbox'] {
          margin: .2em;
          border: 2px solid #000;
        }
      }
    }
  }
}