.individual-block {
  background: #000;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.25em;
  //margin-top: 2.5em;

  @media (min-width: $lg-screen) {
    flex-direction: row;
    margin-top: 2.5em;
  }

  &-text {
    width: 100%;

    @media (min-width: $lg-screen) {
      padding: 2.5em;
      padding-left: 10em;
      width: 50%;
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    @media (min-width: $lg-screen) {
      justify-content: center;
      padding: 2.5em;
      padding-right: 5em;
      width: 50%;
    }
  }

  & .title {
    color: $yellow-color;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1em;

    @media (min-width: $lg-screen) {
      font-size: 2em;
    }
  }

  & .subtitle {
    color: #fff;
    font-size: .8em;
    font-weight: 700;
    margin-top: .5em;

    @media (min-width: $lg-screen) {
      font-size: 1em;
      margin-top: 1.5em;
    }
  }

  & .btn.btn-big {
    font-size: 1em;
    padding: .75em 1em;
    margin-top: 1em;

    @media (min-width: $lg-screen) {
      margin-top: 0;
    }
  }
}