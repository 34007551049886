.about {
  padding: 1.25em;
  max-width: 1700px;
  margin: auto;

  @media (min-width: $lg-screen) {
    display: flex;
    padding: 2.5em;
  }

  &-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $lg-screen) {
      width: 50%;
    }

    &-content {
      transform: scale(.85) translateX(-1em);
      margin-bottom: 5em;

      @media (min-width: $lg-screen) {
        width: max-content;
        transform: scale(1.3) translate(-3em, 0em);
      }

      & img:nth-child(1) {
        width: 14em;
        z-index: 6;
        position: absolute;
        transform: translateX(13em);
      }

      & img:nth-child(2) {
        width: 24em;
        z-index: 5;
        position: relative;
        transform: translateY(4em);
      }

      & span {
        display: block;
        width: 24em;
        height: 18em;
        z-index: 4;
        background: $yellow-color;
        transform: translateY(-12em) translateX(2em);
        position: absolute;
      }
    }
  }

  &-text {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: $lg-screen) {
      width: 50%;
    }

    & .title {
      color: $orange-color;
      font-size: 3.75em;
      font-weight: 700;
      line-height: 1.65;
    }

    & .subtitle {
      font-size: 1.5em;
      font-weight: 700;
    }

    & .text {
      color: $black-color;
      font-size: 1em;
      font-weight: 500;
      margin-top: 1.25em;
    }

    & .icon-block {
      display: flex;
      margin-top: 1.25em;

      & .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        margin-right: 1.25em;

        & img {
          width: 250px;
        }
      }

      & .text {
        color: $black-color;
        font-weight: 700;
      }
    }

    & ul {
      padding: 1.25em;
    }

    & li {
      margin-bottom: 5px;
    }

    & li::marker {
      content: url("../../img/icons/li.png");
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}