.btn {
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: max-content;
  background: #000;
  padding: 1rem .75rem;

  &-black {
    color: $yellow-color;
    background: #000;
  }

  &-yellow {
    color: #000;
    background: $yellow-color;
  }

  &-white {
    color: #000;
    background: #fff;
  }

  &-big {
    font-size: 1em;
    padding: .75em 1em;
    font-weight: 700;
  }

  &-icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: .5rem;

    & img {
      display: block;
      width: 20px;
      height: auto;
    }
  }
}

.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 2px;
  cursor: pointer;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 2px solid #000000;
  border-radius: 5px;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: $yellow-color;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: $orange-color;
}

.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 6px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 3;
  transform: scale(0);
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}

.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}

ul.list {
  //padding: 1.25em;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  & li {
    //margin-bottom: 5px;
    //margin-right: 20px;
    //margin-left: 20px;
    display: flex;
    align-items: start;
    //width: max-content;

    &::before {
      background-image: url("../../img/icons/li.png");
      background-size: 1.2em 1.2em;
      content: "";
      display: inline-block;
      //margin-left: -1em;
      width: 1.2em;
      height: 1.2em;
    }
  }
}