.service {
  background: linear-gradient(355deg, #FFF300 52.61%, rgba(255, 243, 0, 0.00) 93.43%);

  @media (min-width: $lg-screen) {
    margin: 1.25em;
  }

  &-up {
    padding: 1.25em;

    @media (min-width: $lg-screen) {
      padding: 2.5em;
    }

    & .title {
      color: $orange-color;
      font-size: 2.75em;
      font-weight: 700;
      line-height: 1.65;

      @media (min-width: $lg-screen) {
        font-size: 3.75em;
      }
    }

    & .subtitle {
      font-size: 1em;
      font-weight: 700;

      @media (min-width: $lg-screen) {
        font-size: 1.5em;
        width: 60%;
      }
    }

    & .text {
      color: $black-color;
      font-size: .85em;
      font-weight: 500;
      margin-top: 1.25em;

      @media (min-width: $lg-screen) {
        font-size: 1em;
        width: 40%;
      }
    }
  }

  &-down-desktop {
    background: url("../../img/Service.png"), lightgray 50% / cover no-repeat;
    display: none;
    flex-direction: column;

    @media (min-width: $lg-screen) {
      //margin: 1.25em;
      display: flex;
      flex-direction: row;
    }

    &-content {
      width: 100%;
      padding: 2.5em;
      //margin-bottom: 1.25em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: $lg-screen) {
        width: 50%;
      }

      & .title {
        color: #fff;
        font-size: 1.5em;
        font-weight: 700;

        @media (min-width: $lg-screen) {
          font-size: 2em;
        }

        &-yellow {
          color: $yellow-color;
        }
      }

      & .links {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;

        & a {
          color: #fff;
          width: max-content;
          text-transform: uppercase;
          font-weight: bold;
          transition: background-color 0.3s;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          & img {
            position: relative;
            transform: none;
          }
        }
      }
    }

    &-form {
      width: 100%;
      padding: 2.5em;

      @media (min-width: $lg-screen) {
        width: 50%;
      }

      &-image {
        background: linear-gradient(359deg, #FFF 0.75%, rgba(255, 255, 255, 0.00) 99.38%);
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          display: block;
          transform: translateY(1.25em);

          @media (min-width: $lg-screen) {
            display: none;
          }
        }
      }

      & form {
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 2.5em 1.75em;
        gap: 10px;
        border-radius: 20px;

        & .title {
          font-size: 1.5em;
          font-weight: 600;
          line-height: 1.25em;

          &-orange {
            color: $orange-color;
          }
        }

        & input[type='text'] {
          border: 2px solid #000;
          border-radius: 20px;
          padding: 1em;

          &::placeholder {
            color: #000;
            font-weight: 600;
          }
        }

        & button {
          background: $yellow-color;
          border: 2px;
          border-radius: 20px;
          padding: 1em;
          color: #000;
          font-weight: 600;

          &:disabled {
            background: #aaa;
            cursor: no-drop;
          }
        }

        & .allow-policy {
          display: flex;
          align-items: start;

          & label {
            display: block;
          }

          & input[type='checkbox'] {
            margin: .2em;
            border: 2px solid #000;
          }
        }
      }
    }
  }

  &-down {
    display: flex;
    flex-direction: column;

    @media (min-width: $lg-screen) {
      //margin: 1.25em;
      flex-direction: row;
      display: none;
    }

    &-content {
      background: url("../../img/Service.png"), lightgray 50% / cover no-repeat;
      width: 100%;
      padding: 1.25em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-position: center;
      background-size: cover;

      & .title {
        color: #fff;
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 20em;

        &-yellow {
          color: $yellow-color;
        }
      }

      & .links {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;

        & a {
          color: #fff;
          width: max-content;
          text-transform: uppercase;
          font-weight: bold;
          transition: background-color 0.3s;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          & img {
            position: relative;
            transform: none;
          }
        }
      }
    }

    &-form {
      width: 100%;
      padding: 1.25em;
      background: #fff;

      & .border {
        background: #000;
        border-radius: 20px;
        padding: 1.25em;
      }

      @media (min-width: $lg-screen) {
        width: 50%;
      }

      &-image {
        background: linear-gradient(359deg, #FFF 0.75%, rgba(255, 255, 255, 0.00) 99.38%);
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          display: block;
          transform: translateY(1.25em);

          @media (min-width: $lg-screen) {
            display: none;
          }
        }
      }

      & form {
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 2.5em 1.75em;
        gap: 10px;
        border-radius: 20px;

        & .title {
          font-size: 1.5em;
          font-weight: 600;
          line-height: 1.25em;

          &-orange {
            color: $orange-color;
          }
        }

        & input[type='text'] {
          border: 2px solid #000;
          border-radius: 20px;
          padding: 1em;

          &::placeholder {
            color: #000;
            font-weight: 600;
          }
        }

        & button {
          background: $yellow-color;
          border: 2px;
          border-radius: 20px;
          padding: 1em;
          color: #000;
          font-weight: 600;
        }

        & .allow-policy {
          display: flex;
          align-items: start;

          & label {
            display: block;
          }

          & input[type='checkbox'] {
            margin: .2em;
            border: 2px solid #000;
          }
        }
      }
    }
  }
}