.question {
  background: $orange-color;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 1.25em;
  margin-top: 2.5em;

  @media (min-width: $lg-screen) {
    justify-content: center;
    flex-direction: row;
    margin-top: 7.5em;
  }

  &-image {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $lg-screen) {
      padding: 1.25em;
      height: 10em;
      width: 30%;
    }
  }

  &-content {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;

    @media (min-width: $lg-screen) {
      width: 40%;
      height: 10em;
      margin-top: 0;
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    @media (min-width: $lg-screen) {
      justify-content: center;
      padding: 1.25em;
      width: 20%;
      height: 10em;
    }
  }

  & .subtitle {
    color: #000;
    font-size: .8em;
    font-weight: 600;

    @media (min-width: $lg-screen) {
      font-size: 1.2em;
      width: 100%;
      max-width: 100%;
      margin: auto;
    }
  }

  & .btn.btn-big {
    font-size: 1em;
    padding: .75em 1em;
    margin-top: 1em;

    @media (min-width: $lg-screen) {
      margin-top: 0;
    }
  }
}