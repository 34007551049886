.footer {
  background: #000;
  display: flex;
  flex-direction: column;
  padding: 1.25em;

  @media (min-width: $lg-screen) {
    flex-direction: row;
  }

  &-brand {
    width: 100%;
    color: #fff;

    @media (min-width: $lg-screen) {
      width: 35%;
    }

    & img {
      width: 70%;
    }

    & .title {
      font-weight: 900;
      font-size: 1.3rem;
    }

    & .subtitle {
      width: 70%;
      margin-top: 1em;
    }
  }

  &-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

    @media (min-width: $lg-screen) {
      width: 21.66%;
    }

    &-title {
      color: #fff;
      font-size: 1.3rem;
      font-weight: 900;
      text-transform: uppercase;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }

    &-item {
      color: #fff;
      font-weight: 600;
    }
  }
}