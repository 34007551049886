.catalog {
  padding: 1.25em;

  & .title {
    color: $orange-color;

    @media (min-width: $lg-screen) {
    }
  }

  & .subtitle {
    font-size: 1em;
    font-weight: 700;

    @media (min-width: $lg-screen) {
      font-size: 1.5em;
    }
  }
  
  &-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #000;
    //width: 100%;
    transition: all .3s;
    //margin: 2.5em;
    padding: 1.25em;
    height: 600px;

    & .title {
      color: $yellow-color;
    }

    & .text {
      color: #fff;
      font-size: .7em;

      @media (max-width: $md-screen) {
        font-size: .5em;
        line-height: 1.2em;
        margin-top: 10px;
      }
    }

    & li {
      color: #fff;
      font-size: .7em;
      font-weight: 700;

      @media (max-width: $md-screen) {
        font-size: .6em;
        line-height: 1.2em;
        width: 100%;
      }

      &::before {
        //margin-left: 1em;
        //width: 3em;
      }
    }

    & .btn {
      padding: .4em;
      font-size: .9em;
      margin: auto;
      font-weight: 700;
      transition: background-color .3s;

      & .cart-white {
        display: none;
      }
    }
  }

  & .slick-slider {
    padding-top: 50px;

    & .slick-list {
      padding-top: 30px;
    }
  }

  & .slick-slide {
    padding: 2em;
    padding-top: 4em;
    padding-bottom: 4em;
    opacity: 0;
    transition: opacity .3s;

    &.slick-active {
      opacity: 1;
    }
  }

  & .mobile {
    & .slick-slide {
      padding: 2em;
      padding-top: 4em;
      padding-bottom: 4em;
      opacity: 1;
      transition: opacity .3s;

      &.slick-active {
        opacity: 1;
      }
    }
  }

  & .slick-center {
    & .catalog-item {
      background: $yellow-color;
      transform: scale(1.2);

      & .title {
        color: #000;
      }

      & .text {
        color: #000;
      }

      & li {
        color: #000;
      }
      
      & .btn {
        color: #fff;
        background: #000;

        & .cart-black {
          display: none;
        }

        & .cart-white {
          display: block;
        }
      }
    }
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;

    & .arrows {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;
      margin-top: 20px;
    }

    & .btn {
      padding: .5em;
      font-size: .9em;
      font-weight: 700;
    }

    &.mobile {
      & .btn {
        color: #fff;
      }
    }
  }

  & .desktop {
    display: none;

    @media (min-width: $lg-screen) {
      display: block;
    }
  }

  & .mobile {
    display: block;

    @media (min-width: $lg-screen) {
      display: none;
    }
  }
}